<template>
    <div>
        <Filters
            :perPage="perPage"
            :perPageOptions="perPageOptions"
            :searchQuery="searchQuery"
            :isLoadingSomething="isLoadingData || isLoadingMembrships"
            @refresh-data="reloadMemberships"
            @change-per-page="changePerPage"
        />
        <hr>
        <TableMemberships
            :memberships="membershipsData"
            :tableColumns="tableColumns"
            :sortBy="sortBy"
            :isSortDirDesc="isSortDirDesc"
            :refMembershipsListTable="refMembershipsListTable"
            :categories="categories"
            :isLoadingMembrships="isLoadingData || isLoadingMembrships"
            @set-tab-index="setTabIndex"
            @reload-memberships="reloadMemberships"
            fixed
            responsive
            small
            style="overflow:auto"
        />

        <PaginationTable

            :currentPage="currentPage"
            :totalRows="memberships.length"
            :perPage="perPage"
            @change-page="changePage"
        />

    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState, } from 'vuex'
import Filters from '@/modules/fivesClub/components/catalogs/memberships/Filters'
import TableMemberships from '@/modules/fivesClub/components/catalogs/memberships/TableMemberships'
import PaginationTable from '@/modules/fivesClub/components/catalogs/memberships/PaginationTable'

export default {

    components:{
        TableMemberships,
        PaginationTable,
        Filters
    },
    async created() {
        await this.init()
    },
    data() {
        return {
            isLoadingData: false,
            isLoadingMembrships: false,
            perPage: 10,
            currentPage: 1,
            perPageOptions: [5, 10, 15, 20, 25, 50, 100],
            searchQuery: '',
            sortBy: 'id',
            isSortDirDesc: false,
            statusFilter: null,
            refMembershipsListTable : null,
            tableColumns: [
                { key: 'code', label: 'Código', class: "text-nowrap" },
                { key: 'name', label: 'Nombre', sortable: true  },
                { key: 'paymenttypename', label: 'Tipo de Pago' },
                { key: 'returntypename', label: 'Tipo de Retorno' },
                { key: 'status', label: 'Status', class: 'text-center' },
                { key: 'temporalDataStatus', label: 'Status Revisión', formatter: value => {
                    return value ? 'Esperando por Autorización': '-'
                }},
                { key: 'actions', label: 'Acciones', class: 'text-center' },
            ],
        }
    },
    computed: {
        ...mapState('fivesClubCatalogs',['memberships','categories','blackouts','retornos','paymentTypes']),
        ...mapGetters('fivesClubCatalogs', ['filteredMemberships']),
        membershipsData(){
            return this.filteredMemberships?.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
        },
    },
    methods:{
        ...mapActions('fivesClubCatalogs',['getInitialContentForMemberships', 'fetchMemberships']),
        ...mapMutations('fivesClubCatalogs', ['setMemberships','setTypeMemberships','setPaymentTypes','setBlackouts','setRetornos','setCategories']),
        async init(){
            this.isLoadingData = true
            const response = await this.getInitialContentForMemberships()
            this.isLoadingData = false
        },
        changePage(page){
            this.currentPage = page
        },
        changePerPage(perPage){
            this.perPage = perPage
        },

        setTabIndex(index){
            this.$emit('set-tab-index', index)
        },
        async reloadMemberships(){
            this.isLoadingMembrships = true
            await this.fetchMemberships()
            this.isLoadingMembrships = false
        }
}
}
</script>

<style lang="scss" >

@media (max-width: 1280px) {
.b-table {
    max-height: 369px;
}
.b-table th  {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
}
.b-table th div {

    font-size: 10px;
}
.b-table td {
    padding: 5px !important;
    font-size: 9px;
}
.buttonInfo button{
    padding: 9px;
}

.buttonInfo {
    display: table-cell;
}
}
</style>
