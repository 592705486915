<template>
  <div>
    <b-table
      sticky-header="400px"
      :items="memberships"
      :fields="tableColumns"
      :tbody-tr-class="rowClass"
      :filter="filter.queryMembership"
      primary-key="id"
      :sort-by.sync="isSortBy"
      show-empty
      empty-text="No se encontraron membresías"
      :sort-desc.sync="isSortDir"
      class="position-relative mb-0"
      v-if="memberships.length"
      :busy.sync="isLoadingMembrships"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner> <br>
          <strong>Cargando Membresias</strong>
        </div>
      </template>

      <template #cell(status)="row">
        <span class="text-nowrap">
          <b-badge pill :variant="row.item.status ? 'success' : 'danger'">
            {{ row.item.status ? "Activo" : "Desactivado" }}
          </b-badge>
        </span>
      </template>

      <template #cell(actions)="row" v-if="can('fivesclub_membership_show_membership_list_action_button')">
        <div class="text-nowrap">
          <!-- Dropdown -->
          <b-dropdown toggle-class="p-0" no-caret right :class="{ 'd-none': (!row.item.status || !!membershipToEdit && membershipToEdit == row.item.id) && !row.item.temporalDataStatus }">
            <template #button-content>
              <b-button variant="primary" size="sm">
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                  style="color: white"
                />
              </b-button>
            </template>
            <b-dropdown-item @click="setMembershipToEdit(row.item)" v-can="'fivesclub_membership_show_membership_list_action_button_edit_membership'">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50"> Editar membresía </span>
            </b-dropdown-item>
            <b-dropdown-item  @click="showModalInfo(row.item.id)" v-can="'fivesclub_membership_show_membership_list_action_button_edit_payment'">
              <feather-icon icon="FilePlusIcon" />
              <span class="align-middle ml-50">Descuento de Membresias</span>
            </b-dropdown-item>
          </b-dropdown>
          <!-- <br> -->
          <b-form-checkbox
            :class="{ 'd-none': row.item.status || (!row.item.status && row.item.temporalDataStatus) }"
            v-model="row.item.status"
            v-b-tooltip.hover.v-warning
            title="Cambiar Status"
            :value="true"
            switch
            inline
            :unchecked-value="false"
            @change="changeStatus(row.item, $event)"
            class="custom-control-success m-0"
          />
          <b-spinner
            label="Loading..."
            variant="success"
            v-if="!!membershipToEdit && membershipToEdit == row.item.id"
          />
          <ModalDescount :membershipsInfo="row.item" :categories="categoriesArray" />
        </div>
      </template>
    </b-table>

    <b-alert variant="warning" v-else show>
      <div class="alert-body"> <feather-icon icon="InfoIcon" class="mr-50" />
        <span><strong>¡Sin registros!</strong> No se encontraron membresías registradas.</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import { createArraysToSaveAMembership, createArraysTocategoryEdit, benefitsCuttoffBlackouts } from '@/helpers/fivesClubHelper'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { showAlertMessage } from '@/helpers/helpers'
import ModalDescount from '@/modules/fivesClub/components/catalogs/memberships/ModalDescount';
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [utils, acl],
  props: {
    memberships: {
      type: Array,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    isSortDirDesc: {
      type: Boolean,
      required: true,
    },
    isLoadingMembrships: {
      type: Boolean,
      required: true,
    },
    categories: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      isSortDir: this.isSortDirDesc,
      isSortBy: this.sortBy,
      isLoadingMembership: false,
      membershipToEdit: null,
      categoriesArray:[]
    };
  },
  components: {
    ModalDescount,
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('fivesClubCatalogs', ['filter']),
  },

  methods: {
    ...mapActions('fivesClubCatalogs', [ 'fetchSeasons', 'updateMembership', 'getDescountMemberships' ]),
    ...mapMutations('fivesClubCatalogs', ['setSelectedMembership', 'setSeasons', 'setCutoffs', ]),
    setMembershipToEdit(membership) {
      // const data = {...membership } //rompo la fucking referencia
      // const membresia = benefitsCuttoffBlackouts(data)
      // membresia.newBenefits = []
      // membresia.newCutoffs = []
      // membresia.benefitsAvailable = []
      // this.setSelectedMembership(membresia)
      this.$router.push({ name: 'edit-membership', params: { idMembership: membership.id, typeReq: 'data' } })
    },
    async changeStatus(item, event) {
      this.membershipToEdit = item.id;
      const [blackouts, benefits, cuttoffs] = createArraysToSaveAMembership(
        item.blackouts,
        item.benefits,
        item.Cutoff
      );
      const payload = {
        idMembership: item.id,
        code: item.code,
        name: item.name,
        idType: item.typeMembershipsId,
        showReport: Boolean(item.showReport),
        excludeCalculo: Boolean(item.excludeFeeCalculation),
        paymentType: item.paymentType,
        returnType: item.returnType,
        note: item.notes,
        status: Boolean(event),
        //arreglos
        benefits,
        blackout: blackouts,
        cutoff: cuttoffs,
      };
      const { status, message } = await this.updateMembership(payload);
      this.membershipToEdit = null;
      if (status) {
        showAlertMessage('Ok: Status actualizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
        this.$emit('reload-memberships')
      } else {
        item.status = !item.status
        showAlertMessage('No se pudo actualizar el status', 'InfoIcon', 'Hubo un error al actualizar el status. Por favor intentelo más tarde', 'warning', 4000, 'bottom-right' )
      }
    },
    async showModalInfo(id) {
      const categoryInfo = await this.getDescountMemberships(id);
      this.categoriesArray = createArraysTocategoryEdit(
        this.categories,
        categoryInfo
      );
      this.$root.$emit("bv::show::modal", "modal-descount" + id);
    },
  },
};
</script>
<style>
.isCentered{
    align-content: center;
    align-items: center;
}
.isSpinner{
    padding: 2rem;
}
</style>